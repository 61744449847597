import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ReCAPTCHA from "react-google-recaptcha";

import Navigation from "../Components/Navigation";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { getSubscribers, logout } from "../services/main.service";

const Subscribers = () => {
  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  const [loading, setLoading] = useState(true);

  const [emails, setEmails] = useState([]);

  const recaptchaRef = useRef();

  const [pageNumber, setPageNumber] = useState(0);

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const handleLogout = async () => {
    await logout();
    navigate("/admin");
  };

  const pageCount = Math.ceil(emails.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    (async function () {
      const token = await recaptchaRef.current.executeAsync();
      getSubscribers(token)
        .then((response) => {
          console.log(response.data.content);
          if (response.data.content !== null) {
            setEmails(response.data.content);
            setLoading(false);
          }
        })
        .catch((err) => {
          setEmails([{ email: "no_data", date: "no_data" }]);
          setLoading(false);
        });
    })();
  }, []);

  const displayData = emails
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">
            {pageNumber > 0 ? index + 1 + pageNumber * 10 : index + 1}
          </th>
          <td>{item.email}</td>
          <td>{item.date}</td>
        </tr>
      );
    });

  return !loading ? (
    <div>
      <Navigation logout={true} handleLogout={handleLogout} />
      <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center">
        <h1 className="primary-h" style={{ fontSize: 32 }}>
          Subscribed Clients
        </h1>
        <table
          className="table table-striped my-3 text-center"
          style={{
            width: width > 992 ? "50%" : "95%",
          }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Email</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>{displayData}</tbody>
        </table>
        <div className="d-flex justify-content-center">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={changePage}
            containerClassName={"pagination-btn"}
            disabledClassName={"pagination-disabled"}
            activeClassName={"pagination-active"}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="centered">
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LfcsbogAAAAABENcyb1aUseV-xfIxuA3JV99ZB4"
        style={{ display: "none" }}
      />
      <div className="loading-spinner" />
    </div>
  );
};

export default Subscribers;
