import http from "../common/http-common";

export const login = (email, pwd) => {
  return http.post("/login", {
    email: email,
    password: pwd,
  });
};

export const logout = () => {
  return http.get("/logout");
};

export const getSubscribers = (token) => {
  return http.post("/subscribers/get", {
    token: token,
  });
};

export const subscribeClient = (email, token) => {
  return http.post("/subscribers/add", {
    email: email,
    token: token,
  });
};
