const TeamCard = ({ source, name, desc, link }) => {
  return (
    <div className="col-md-auto">
      <aside className="profile-card">
        <div className="mask-shadow"></div>
        <header>
          <img src={source} alt="avatar" />
          <h1>{name}</h1>
          <h2 dangerouslySetInnerHTML={{ __html: desc }} />
        </header>

        <ul className="profile-social-links">
          {link && (
            <li>
              <a href={link} target="_blank" rel="noreferrer">
                <i className="bi bi-globe" />
              </a>
            </li>
          )}
        </ul>
      </aside>
    </div>
  );
};

export default TeamCard;
