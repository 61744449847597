import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Navigation from "../Components/Navigation";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { login } from "../services/main.service";

const Login = ({ setLogged }) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [err, setErr] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [btnText, setBtnText] = useState("Login");

  const handleLogin = () => {
    if (email === "") {
      setErr("Please enter your email.");
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErr("Please enter a valid email.");
    } else if (password === "") {
      setErr("Please enter your password.");
    } else {
      setBtnText("Authenticating");
      setLoading(true);
      login(email, password)
        .then((response) => {
          if (response.data.content === "success") {
            setBtnText("Success");
            setEmail("");
            setPassword("");
            setLogged(true);
            setTimeout(() => navigate("/admin/data"), 1500);
          }
          setPassword("");
          setLoading(false);
        })
        .catch((err) => {
          if (err === "auth/user-not-found") {
            setErr("User not found.");
          } else if (err === "auth/wrong-password") {
            setErr("Password mismatch.");
          }
          setLogged(false);
          setBtnText("Login");
          setPassword("");
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Navigation />
      <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center">
        <div className={`form text-center ${width > 992 ? "w-50" : "w-100"}`}>
          <h1>ADMIN LOGIN</h1>

          {err && <label className="error">{err}</label>}

          <div className="form-group">
            <input
              type="email"
              placeholder="Email"
              className="form-input shadow-none"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              className="form-input shadow-none"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            className="primary-btn my-3"
            disabled={loading}
            onClick={handleLogin}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
