import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import assetFour from "../assets/asset_4.png";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { subscribeClient } from "../services/main.service";

const EmailForm = () => {
  const { width } = useWindowDimensions();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [err, setErr] = useState(undefined);

  const recaptchaRef = useRef();

  const handleSubmit = async () => {
    if (email === "") {
      setErr("Please enter your email.");
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErr("Please enter a valid email.");
    } else {
      setLoading(true);
      const token = await recaptchaRef.current.executeAsync();
      subscribeClient(email, token).then((response) => {
        if (response.data.content === "success") {
          setLoading(false);
          setSuccess(true);
          setErr(undefined);
        } else if (response.data.content === "Already subscribed") {
          setLoading(false);
          setSuccess(false);
          setErr(`User with the email ${email} is already subscribed.`);
        }
      });
    }
  };

  return (
    <div
      id="subscribe"
      className="container justify-content-center"
      style={{ marginTop: 0, scrollMarginTop: 200 }}
    >
      <center>
        {width > 992 && (
          <img
            src={assetFour}
            style={{
              position: "absolute",
              maxWidth: "100%",
              left: 0,
              userSelect: "none",
              pointerEvents: "none",
              marginTop: success ? "-3%" : "0%",
              zIndex: -1,
            }}
          />
        )}
        <h2 style={{ color: "var(--dark)", marginTop: 20 }}>
          Join our email list for future updates.
        </h2>
        <br />
        {err && <label className="error">{err}</label>}
        <br />
        <br />
        <div
          className="hstack justify-content-center"
          style={{ marginTop: -20 }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LfcsbogAAAAABENcyb1aUseV-xfIxuA3JV99ZB4"
            style={{ display: "none" }}
          />
          {!success && (
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              value={email}
              className="form-input"
              placeholder="your@email.com"
              required={true}
            />
          )}
          {!success && (
            <div
              className="g-recaptcha"
              data-sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
            ></div>
          )}
          <button
            className="primary-btn"
            type="button"
            disabled={loading || success}
            onClick={handleSubmit}
            style={{ marginTop: success && 30 }}
          >
            {success ? "Success!" : "Subscribe"}
          </button>
        </div>
      </center>
    </div>
  );
};

export default EmailForm;
