import { animated, useSpring } from "react-spring";

const AboutArtist = () => {
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      marginBottom: 40,
      transform: "translateY(-100px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        marginBottom: 40,
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  return (
    <div
      id="about"
      style={{
        position: "relative",
        minWidth: "100vw",
        padding: "100px 40px",
        scrollMarginTop: 140,
      }}
      className="container"
    >
      <div className="row">
        <center>
          <animated.h1 style={titleAnimation}>
            <span className="primary-h">About the Artist</span>
          </animated.h1>
          <br />
          <br />
          <p className="primary-p">
            Hi! I’m Blue Slushie. I’m a 12-year old girl from California and I
            have many hobbies. I’m 5'4" and in 6th, almost 7th grade! I really
            want to one day make a great piece of art based off of my dog Coco,
            I would love for my pigs to go viral because I spent a lot of work
            making them! I love many things, I love: gaming, art, motocross,
            animals, and much more. I hope to become a Vet someday so I can help
            all animals from big to small. As of March 6th, 2022, I have 3 dogs!
            And my brother has one as well, I love them all, and I love my pigs
            too.
            <br />
            I wish you well!!
            <br />
            <br />
            .•♫•♬• ℬ𝓁𝓊𝑒 𝒮𝓁𝓊𝓈𝒽𝒾𝑒 •♬•♫•.
          </p>
        </center>
      </div>
    </div>
  );
};

export default AboutArtist;
