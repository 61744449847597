import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import useWindowDimensions from "../hooks/useWindowDimensions";

const RoadmapCard = ({ title, desc }) => {
  const { width } = useWindowDimensions();

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transform: "translateY(0px)",
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.025,
      transform: "translateY(-100px)",
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: width > 992 && 0.9 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.li
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
      className="roadmap-item"
    >
      <div className="roadmap-marker"></div>
      <div className="roadmap-content">
        <h3 className="roadmap-title">{title}</h3>
        <p>{desc}</p>
      </div>
    </motion.li>
  );
};

export default RoadmapCard;
