import Footer from "./Components/Footer";
import Navigation from "./Components/Navigation";
import FAQ from "./sections/FAQ";
import Home from "./sections/Home";
import Roadmap from "./sections/Roadmap";
import Team from "./sections/Team";
import AboutPiggoPalace from "./sections/AboutPiggoPalace";
import AboutArtist from "./sections/AboutArtist";
import AboutPiggos from "./sections/AboutPiggos";
import EmailForm from "./Components/EmailForm";

const App = () => {
  return (
    <div>
      <Navigation />
      <Home />
      <AboutPiggoPalace />
      <Roadmap />
      <AboutPiggos />
      <Team />
      <AboutArtist />
      <EmailForm />
      <FAQ />
      <Footer />
    </div>
  );
};

export default App;
