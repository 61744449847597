import { animated, useSpring } from "react-spring";

import FAQCard from "../Components/FAQCard";

const FAQ = () => {
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      transform: "translateY(-120px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <div
      className="container"
      id="faq"
      style={{
        minWidth: "100vw",
        scrollMarginTop: 20,
        padding: 80,
        background: "rgba(var(--senary-rgb), 0.2)",
      }}
    >
      <div style={{ position: "sticky" }}>
        <center>
          <animated.h1 style={titleAnimation} className="heading-h1">
            <span className="primary-h">FAQ</span>
          </animated.h1>

          <div className="faq-container">
            <FAQCard
              title="What is the supply and Mint Price?"
              text="There will be a total of 10,000 Piggos available for ownership. The Mint price will be 0.025 ETH."
            />
            <FAQCard
              title="What is the Launch Date?"
              text="Piggos Official Launch will be coming soon. Please be careful of scams, double check our website address before you purchase. Only buy from www.piggopalace.com. Launch details will be posted only on our website, Discord, Twitter and Instagram."
            />
            <FAQCard
              title="What type of utility do the Piggos provide?"
              text="Owners of NFT Piggos will proudly be able to assert that they own a piece of history as these are the only Piggo NFTs designed by up and coming Artist Blue Slushe, a 12 year old Girl from California. Piggo NFT owners will be confident knowing that a portion of their NFT purchase price goes to help a real life Piggo in need at the Ironwood Pig Sanctuary. Additionally, Piggo NFT owners get to participate in exclusive Piggo Breading Program, and right to their own Baby Piglet Piggos, have first access to Piggo Merchandise, Events and one day first rights access to The Meta-Piggo Palace, Piglet Palace and Piggo Condos"
            />
            <FAQCard
              title="What are NFTs?"
              text="NFT stands for “non-fungible token” which are unique digital items that are stored and encrypted on the blockchain that people can buy, own, and trade. NFTs can simply be digital art but can have various benefits. They can be treated like membership cards where holders get exclusive access to things like websites, events, merchandise, and more."
            />
            <FAQCard
              title="How do I buy Piggo NFTs?"
              text="<ol style='text-align: left;'><li style='margin-bottom: 10px;'>Sign up for Metamask or Coinbase Wallet and download the extension on your internet browser.</li><li style='margin-bottom: 10px;'>Make sure you have enough Ethereum in your wallet to cover the total cost including gas fees.</li><li style='margin-bottom: 10px;'>On mint day, there will be a Connect button at the top of our website to connect to your wallet.</li><li style='margin-bottom: 10px;'>Click on the mint button and you will be prompted to sign for your transaction. There will be a fee associated with every transaction related to gas prices.</li><li>Once you have purchased your Piggo NFT, it will appear in your wallet and on Opensea.</li></ol>"
            />
          </div>
        </center>
      </div>
    </div>
  );
};

export default FAQ;
