import { animated, useSpring } from "react-spring";

import assetThree from "../assets/asset_3.png";

import img_1 from "../assets/asset_6.png";
import img_2 from "../assets/asset_7.png";
import img_3 from "../assets/asset_8.png";
import img_4 from "../assets/asset_9.png";
import img_5 from "../assets/asset_10.png";
import img_6 from "../assets/asset_11.png";
import img_7 from "../assets/asset_12.png";
import img_8 from "../assets/asset_13.png";
import img_9 from "../assets/asset_14.png";
import img_10 from "../assets/asset_15.png";
import img_11 from "../assets/asset_16.png";

import useWindowDimensions from "../hooks/useWindowDimensions";

const AboutPiggos = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      marginBottom: 40,
      transform: "translateY(-100px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        marginBottom: 40,
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  return (
    <div
      id="about"
      style={{
        position: "relative",
        minWidth: "100vw",
        padding: "100px 40px",
        scrollMarginTop: 140,
      }}
      className="container"
    >
      {width > 992 && (
        <img
          src={assetThree}
          style={{
            position: "absolute",
            maxWidth: "100%",
            left: 0,
            userSelect: "none",
            pointerEvents: "none",
            marginTop: "-15%",
          }}
        />
      )}

      <div className="row" style={{ position: "sticky" }}>
        <center>
          <animated.h1 style={titleAnimation}>
            <span className="primary-h">About the Piggos</span>
          </animated.h1>
          <br />
          <div className="slider">
            <div className="slide-track">
              <div className="slide">
                <img src={img_1} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_2} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_3} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_4} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_5} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_6} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_7} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_8} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_9} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_10} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_11} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_6} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_7} height="125" alt="" />
              </div>
              <div className="slide">
                <img src={img_8} height="125" alt="" />
              </div>
            </div>
          </div>
          <br />
          <p className="primary-p">
            A portion of Piggo NFT Sale proceeds will go to an actual real life
            Piggo Sanctuary –{" "}
            <a
              href="https://www.ironwoodpigs.org/"
              target="_blank"
              style={{
                all: "unset",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              rel="noreferrer"
            >
              <strong>Ironwood Pig Sanctuary in Arizona.</strong>
            </a>
            <br />
            <br />
            There are 10,000 boy and girl Piggos from all walks of life living
            in the Piggo Palace that resides on the Ethereum Blockchain. Some
            Piggos have active lifestyles, some are couch potatoes, others still
            are a little rambunctious, have very active imaginations and can
            tend to get into all sorts of trouble. All are cute and adorable in
            their own way and available for purchase. We will be dropping a few
            original 1/1s in the 1st release so some lucky Piggo owners could be
            the recipients of the rarest of the rare Piggos!
            <br />
            <br />
            Peace to the Piggos!
          </p>
        </center>
      </div>
    </div>
  );
};

export default AboutPiggos;
