import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";

import App from "../App";
import MintConnect from "../sections/MintConnect";
import store from "../redux/store";
import Login from "../sections/Login";
import Subscribers from "../sections/Subscribers";
import { useState } from "react";

const Routing = () => {
  const [isLogged, setLogged] = useState(false);

  return (
    <Router>
      <Provider store={store}>
        <ParallaxProvider>
          <Routes>
            <Route exact path="*" element={<Navigate to="/" />} />
            <Route exact path="/" element={<App />} />
            <Route exact path="/mint" element={<MintConnect />} />
            <Route
              exact
              path="/admin"
              element={<Login setLogged={setLogged} />}
            />
            <Route
              exact
              path="/admin/data"
              element={isLogged ? <Subscribers /> : <Navigate to="/admin" />}
            />
          </Routes>
        </ParallaxProvider>
      </Provider>
    </Router>
  );
};

export default Routing;
