import { animated, useSpring } from "react-spring";

import RoadmapCard from "../Components/RoadmapCard";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Roadmap = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      transform: "translateY(-100px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  return (
    <div
      id="piggomap"
      className="roadmap-bg"
      style={{
        paddingTop: width > 900 && 140,
        scrollMarginTop: 0,
        minWidth: "100vw",
        padding: 80,
        background: "rgba(var(--quaternary-rgb), 0.2)",
      }}
    >
      <center>
        <animated.h1 style={titleAnimation}>
          <span className="primary-h">PiggoMap</span>
        </animated.h1>
        <br />
        <div
          className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2"
          style={{ margin: 40 }}
        >
          <ul className="roadmap roadmap-centered">
            <RoadmapCard
              title={"Piggo Creation"}
              desc={
                "10,000 unique and adorable NFT Piggos are released for sale to good homes."
              }
            />

            <RoadmapCard
              title={"Palace Development"}
              desc={
                "The Piggo Palace is born. Our hard working Development Team stitched together an amazing home for the Piggos to eat, sleep, rest, work and play (and pro-create) 🐖🐷🐖🐷🐖🐷🐖🐷"
              }
            />
            <RoadmapCard
              title={"Special Piggo Release; The Original 12"}
              desc={
                "These are the Piggos that started it all! You could find yourselves owners of the one and only originals as penned by Artist Blue Slushe."
              }
            />
            <RoadmapCard
              title={"Piggo Charity"}
              desc={
                "A portion of every Piggo NFT sale will be donated to Ironwood Pig Sanctuary in AZ. Ironwood is home to over 600 actual real life Piggos and really needs your support to keep their Piggos safe and healthy and well fed!"
              }
            />
            <RoadmapCard
              title={"Piggos Multiply"}
              desc={
                "An soon-to-be-released feature: Let the pro-creation begin; A unique feature in the Piggo Palace is the ability to lend your Piggo (and their traits) to the Piggo Palace Breeding Program; These lent boy Piggos fall in Love with the lent girl Piggos and the Palace lets nature take its course 😉. After a short 60 days, a set of unique Baby Piglet Piggos are born (average of 20 Baby Piglet Piggos per litter), of which the Momma Piggo NFT Owner gets one of the Baby Piggos and the Daddy Piggo NFT owner gets one of the Baby Piglet Piggos AirDropped free of charge (Piglet Piggos from Heaven!). The rest of the Baby Piglet Piggos of each litter go to the Piglet Palace and await their purchase by some lucky family or individual. A portion of the sale of Baby Piglet Piggos NFT sales from the Piglet Palace will also be donated to Ironwood Pig Sanctuary in AZ."
              }
            />
            <RoadmapCard
              title={"Piggo Convention"}
              desc={
                "Another future feature will be for the Palace to host aspecial gathering of Piggo NFT owners will be planned (either virtually or in person) to allow the Piggo Palace Community Members to gather in one place, discuss their unique Piggos and their love of Animals."
              }
            />
            <RoadmapCard
              title={"Piggo Merch"}
              desc={
                "As our success grows, the Piggo Palace will release a line of merchindise featuring Piggos and Piglets from The Palace for those Piggo NFT owners who agree to allow their likeness to be printed on specialized high quality Merchandise available for sale at the Piggo Palace Showroom."
              }
            />
            <RoadmapCard
              title={"Piggo Party(ies)"}
              desc={
                "Our future plans include hosting a series of regional or national parties for Piggo NFT owners in order to celebrate our shared love of Piggos. These parties will bring awareness about Piggo ownership and the ways in which Piggo Palace Community Members can get involved in real life."
              }
            />
            <RoadmapCard
              title={"Piggo Palace in the Metaverse"}
              desc={
                "Own or Rent your own Piece of the Piggo Palace in the Metaverse. Once the Piggo Palace reaches a certain level of success, it is our dream to build an actual Piggo Palace and Piglet Palace in the Metaverse. We all the specialized rooms and amenities as described above, the Meta-Piggo Palace will allow guests to stop by and hangout with other Piggo NFT owners and their invited Guests. A series of Meta-Piggo Parties and Events are planned to be held at this MetaPalace and some lucky NFT owners will get a chance to Own a slice of the palace with their own Piggo-Condo that they can stay in themselves or rent out to other pre-approved guests for short term or long-term rentals."
              }
            />
          </ul>
        </div>
      </center>
    </div>
  );
};

export default Roadmap;
