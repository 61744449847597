import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

import assetOne from "../assets/asset_1.png";
import assetTwo from "../assets/asset_2.png";

import iconOne from "../assets/asset_15.png";
import iconTwo from "../assets/asset_16.png";

import useWindowDimensions from "../hooks/useWindowDimensions";

const AboutPiggoPalace = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      opacity: 0.3,
      scale: 1.1,
    },
    to: [
      {
        opacity: 1,
        scale: 1,
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const controls2 = useAnimation();
  const [ref2, inView2] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    }
  }, [controls2, inView2]);

  return (
    <div
      id="welcome"
      style={{
        position: "relative",
        minWidth: "100vw",
        padding: "80px",
        background: "var(--light)",
        scrollMarginTop: 20,
      }}
      className="container"
    >
      {width > 992 && (
        <img
          src={assetOne}
          style={{
            position: "absolute",
            maxWidth: "100%",
            left: 0,
            userSelect: "none",
            pointerEvents: "none",
            marginTop: "-16%",
          }}
        />
      )}
      {width > 992 && (
        <img
          src={assetTwo}
          style={{
            position: "absolute",
            maxWidth: "100%",
            left: 0,
            userSelect: "none",
            pointerEvents: "none",
            bottom: -300,
          }}
        />
      )}
      <motion.img
        src={iconOne}
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={viewAnim}
        style={{
          position: "absolute",
          right: "12%",
          top: "70%",
          rotate: -15,
          maxWidth: width > 992 ? "12%" : "20%",
          zIndex: 0,
        }}
      />
      <motion.img
        src={iconTwo}
        ref={ref2}
        animate={controls2}
        initial="hidden"
        variants={viewAnim}
        style={{
          position: "absolute",
          top: "16%",
          right: "70%",
          rotate: 15,
          maxWidth: width > 992 ? "12%" : "20%",
          zIndex: 0,
        }}
      />
      <div style={{ position: "sticky" }}>
        <center>
          <animated.h1 style={titleAnimation}>
            <span className="primary-h">Welcome to the Piggo Palace</span>
          </animated.h1>
          <br />
          <br />
          <p className="primary-p">
            The Palace is teeming with Piggos who have come from all over the
            world; some from the wild, some from homes, and some from bad places
            like the Butcher’s.
            <br />
            <br />
            The Piggo Palace features all sorts of rooms and special amenities
            suited for these 10,000 one of a kind Piggos. Born from over 125
            unique traits these Piggos sport a series of unique and rare
            attributes the have mass appeal.
            <br />
            <br />
            In the Palace you will find the Piggo’s favorite places; There is a
            Mud Room, A Spa, A Pond, A Massage Room, A Sports Room, a Kitchen
            and Dining Hall, a Playroom, A Sun Room, A Garage and a Gym, to keep
            these Piggos in shape.
            <br />
            <br />
            All of these Piggos need a home, they love people and they don’t
            need too much of anything! They need a good place to live, would you
            be willing to adopt one? 🐖🐷
            <br />
            <br />
            <span className="primary-h" style={{ fontSize: 48 }}>
              {" "}
              Peace to the Piggos!
            </span>
          </p>
        </center>
      </div>
    </div>
  );
};

export default AboutPiggoPalace;
