import { useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";

import logo from "../assets/logo.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Navigation = ({ logout, handleLogout }) => {
  const { width } = useWindowDimensions();

  const [isVisible, setVisible] = useState(true);
  const [onHover, setOnHover] = useState(false);
  const [currentScrollPos, setCurrentScrollPos] = useState(window.scrollY);

  useEffect(() => {
    if (!onHover && currentScrollPos > 0) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 1250);
      return () => clearTimeout(timer);
    }
  }, [onHover, isVisible, currentScrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setCurrentScrollPos(window.scrollY);
      setVisible(true);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setCurrentScrollPos(window.scrollY);
        setVisible(true);
      });
    };
  }, []);

  const navAnimation = useSpring({
    from: {
      opacity: 0,
      scale: 0.5,
      transform: "translateY(-100px)",
    },
    to: [{ opacity: 1, scale: 1, transform: "translateY(10px)" }],
    config: { mass: 2, tension: 190, friction: 22 },
  });

  const brandAnimation = useSpring({
    from: {
      opacity: 0,
      scale: 1.1,
    },
    to: [{ opacity: 1, scale: 1 }],
    config: { mass: 2, tension: 190, friction: 22 },
  });

  return (
    <nav
      className={`navbar navbar-expand-lg navbar fixed-top ${
        !isVisible && "nav-hidden"
      }`}
      onMouseOver={() => {
        setVisible(true);
        setOnHover(true);
      }}
      onMouseLeave={() => {
        setVisible(true);
        setOnHover(false);
      }}
      style={{ backgroundColor: "var(--accentDark)", marginBottom: 60 }}
    >
      <div className="container-fluid">
        <a
          className="navbar-brand"
          href="/"
          style={{ marginLeft: width > 992 && 80 }}
        >
          <animated.img src={logo} alt="img" style={brandAnimation} />
        </a>
        <button
          className="navbar-toggler shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="bi bi-list"></i>
          </span>
        </button>
        <div
          className="collapse navbar-collapse mb-2 mb-lg-4"
          id="navbarSupportedContent"
          style={{
            marginLeft: width > 992 && 90,
          }}
        >
          <ul className="navbar-nav me-auto">
            <li className="nav-item"></li>
          </ul>
          <ul className="navbar-nav me-5 nav-list text-center">
            <animated.li className="nav-item" style={navAnimation}>
              <a href="/#home" className="nav-link">
                <span className="text">Home</span>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a href="/#welcome" className="nav-link">
                <span className="text">Welcome</span>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a href="/#piggomap" className="nav-link">
                <span className="text">PiggoMap</span>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a href="/#team" className="nav-link">
                <span className="text">Team</span>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a href="/#faq" className="nav-link">
                <span className="text">FAQ</span>
              </a>
            </animated.li>
            {logout && (
              <animated.li className="nav-item" style={navAnimation}>
                <button
                  className="nav-link"
                  onClick={handleLogout}
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                  }}
                >
                  <span className="text">Logout</span>
                </button>
              </animated.li>
            )}
          </ul>
          <ul
            className="navbar-nav ms-auto hstack justify-content-center"
            style={{
              marginRight: width > 992 ? 80 : 60,
              marginTop: width > 992 && 20,
            }}
          >
            <li className="nav-item">
              <a
                className="social-nav"
                href="https://www.instagram.com/theofficialpiggopalace/"
                target="_blank"
                rel="noreferrer"
                data-toggle="tooltip"
                data-placement="top"
              >
                <span>
                  <i className="bi bi-instagram" />
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="social-nav"
                href="https://twitter.com/PiggoPalace"
                target="_blank"
                rel="noreferrer"
                data-toggle="tooltip"
                data-placement="top"
              >
                <span>
                  <i className="bi bi-twitter" />
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="social-nav"
                href="https://discord.gg/J4BuTAdbjZ"
                target="_blank"
                rel="noreferrer"
                data-toggle="tooltip"
                data-placement="top"
              >
                <span>
                  <i className="bi bi-discord" />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
