import "bootstrap-icons/font/bootstrap-icons.css";

import assetFive from "../assets/asset_5.png";

const Footer = () => {
  return (
    <footer className="footer-bar">
      <div className="container" style={{ position: "sticky", zIndex: 1 }}>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h2 className="footer-heading">
              <a href="#" className="logo">
                PiggoPalace
              </a>
            </h2>
            <p className="menu">
              <a href="#home">Home</a>
              <a href="#about">About</a>
              <a href="#piggomap">PiggoMap</a>
              <a href="#team">Team</a>
              <a href="#faq">FAQ</a>
            </p>
            <ul className="footer-bar-social p-0">
              <li>
                <a
                  href="https://www.instagram.com/theofficialpiggopalace/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <span>
                    <i className="fab fa-instagram" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/PiggoPalace"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <span>
                    <i className="fab fa-twitter" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/J4BuTAdbjZ"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <span>
                    <i className="fab fa-discord" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col text-center">
            <p className="copyright">
              Copyright &copy;
              {` ${new Date().getFullYear()}`} All rights reserved
            </p>
          </div>
        </div>
      </div>
      <img
        src={assetFive}
        style={{
          zIndex: 0,
          position: "absolute",
          maxWidth: "100%",
          maxHeight: "100%",
          userSelect: "none",
          pointerEvents: "none",
          marginTop: "-22%",
        }}
      />
    </footer>
  );
};

export default Footer;
