import { animated, useSpring } from "react-spring";

import teamOne from "../assets/asset_20.png";
import teamTwo from "../assets/asset_18.png";
import teamThree from "../assets/asset_17.png";
import iconOne from "../assets/asset_7.png";

import TeamCard from "../Components/TeamCard";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Team = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      transform: "translateY(-100px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  return (
    <div
      id="team"
      className="container"
      style={{
        minWidth: "100vw",
        minHeight: "60vh",
        marginTop: width > 992 && 150,
        marginBottom: width > 992 && 150,
        scrollMarginTop: 260,
      }}
    >
      {width > 992 && (
        <svg
          style={{
            position: "absolute",
            zIndex: 999,
            userSelect: "none",
            pointerEvents: "none",
          }}
          viewBox="0 0 300 300"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ff7fc920"
            d="M47.2,-53.4C62.7,-43.3,77.8,-29.8,82.6,-13C87.5,3.9,82.2,24.1,70.7,37.9C59.3,51.7,41.7,59.1,23.4,66.9C5,74.7,-14.1,83,-32.3,80.1C-50.6,77.3,-67.9,63.3,-75.8,45.7C-83.6,28.1,-81.9,7,-77.6,-13.1C-73.3,-33.1,-66.5,-52.1,-53.1,-62.6C-39.7,-73.1,-19.9,-75.3,-2,-72.9C15.9,-70.5,31.7,-63.6,47.2,-53.4Z"
            transform="translate(155 75)"
          />
        </svg>
      )}
      <center>
        <div className="row">
          <animated.h1 style={titleAnimation}>
            <span className="primary-h">Team</span>
          </animated.h1>
        </div>
        <div className="row justify-content-md-center">
          <TeamCard
            source={teamOne}
            name={"Blue Slushe"}
            desc={"Piggo Artist"}
          />
          <TeamCard
            source={teamTwo}
            name={"Daddy Piggo"}
            desc={"Palace Community Director"}
          />
        </div>
        <div className="row justify-content-md-center">
          <TeamCard
            source={teamThree}
            name={"Jacob Gephart"}
            desc={"Techera Solutions<br/>End to End NFT Collection Development"}
            link={"https://www.techera.solutions/"}
          />
        </div>
        <img
          src={iconOne}
          style={{
            position: "relative",
            right: "20%",
            marginTop: width > 992 ? -25 : 0,
            transform: "rotate(15deg)",
            maxWidth: width > 992 ? "12%" : "20%",
            zIndex: 0,
          }}
        />
      </center>
    </div>
  );
};

export default Team;
