import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

import iconOne from "../assets/asset_19.png";
import iconTwo from "../assets/asset_10.png";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Link } from "react-router-dom";

const Home = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      opacity: 0,
      transform: "translateX(-120px)",
    },
    to: [
      {
        opacity: 1,
        transform: "translateX(15px)",
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const controls2 = useAnimation();
  const [ref2, inView2] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    }
  }, [controls2, inView2]);

  return (
    <div id="home" style={{ height: "100vh" }}>
      <div
        style={{
          position: "absolute",
          left: "25%",
          maxHeight: "25%",
          top: "auto",
          bottom: 0,
          zIndex: 1,
        }}
      >
        <motion.img
          src={iconOne}
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={viewAnim}
          style={{
            position: "relative",
            right: width > 992 ? "5%" : "30%",
            rotate: -15,
            marginBottom: width < 992 && 40,
            maxWidth: width > 992 ? "40%" : "28%",
            zIndex: 0,
          }}
        />
        <motion.img
          src={iconTwo}
          ref={ref2}
          animate={controls2}
          initial="hidden"
          variants={viewAnim}
          style={{
            position: "absolute",
            right: width > 992 ? "-120%" : 0,
            rotate: 15,
            top: "auto",
            maxWidth: width > 992 ? "40%" : "28%",
            bottom: width < 992 ? "5%" : "140%",
            zIndex: 0,
          }}
        />
      </div>
      <div className="centered">
        <center>
          <animated.div style={titleAnimation}>
            <span
              style={{
                fontSize: width > 992 ? 90 : 36,
                fontWeight: 600,
                letterSpacing: 10,
                textAlign: "center",
                color: "var(--dark)",
              }}
            >
              <div id="main">PIGGOPALACE</div>
            </span>
            {width < 992 && <br />}
            <Link to="/mint" style={{ all: "unset" }}>
              <button className="primary-btn">
                <span>Mint Coming Soon</span>
              </button>
            </Link>
            <a href="#subscribe" style={{ all: "unset" }}>
              <button
                className="primary-btn"
                style={{
                  marginLeft: width > 992 && 20,
                  marginTop: width < 992 && 20,
                }}
              >
                <span>Signup for Whitelist!</span>
              </button>
            </a>
            <div className="primary-p" style={{ width: "75%", marginTop: 20 }}>
              A portion of Piggo NFT Sale proceeds will go to an actual real
              life Piggo Sanctuary –{" "}
              <a
                href="https://www.ironwoodpigs.org/"
                target="_blank"
                style={{
                  all: "unset",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                rel="noreferrer"
              >
                <strong>Ironwood Pig Sanctuary in Arizona.</strong>
              </a>
            </div>
          </animated.div>
        </center>
      </div>
    </div>
  );
};

export default Home;
